import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Zendesk",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/zendesk.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Zendesk`}</h1>
    <p>{`Reply to Crisp conversations from Zendesk`}</p>
    <hr></hr>
    <p>{`You just discovered Crisp, think it's amazing (which is true), but your Team is stuck with Zendesk? This integration is made for you. This plugin lets you connect Crisp to Zendesk. All your conversations are then synced between Crisp and Zendesk, so your team can reply to Crisp chats from both Crisp and Zendesk`}</p>
    <p>{`Benefits of using Crisp and Zendesk:`}</p>
    <ul>
      <li parentName="ul">{`Enjoy the best Crisp experience in Zendesk`}</li>
      <li parentName="ul">{`A ticket is created in Zendesk when a message is sent from Crisp`}</li>
      <li parentName="ul">{`Integrate Crisp to your existing workflow and avoid using too many apps.`}</li>
      <li parentName="ul">{`Messages you send and receive on/from Zendesk with a visitor are still stored in your Crisp dashboard. Your chat history is safe.`}</li>
      <li parentName="ul">{`Messages sent to a visitor from a Crisp app are synced to Zendesk, so that everything gets visible in the same place.`}</li>
      <li parentName="ul">{`Share files and images`}</li>
      <li parentName="ul">{`Search previous conversations using the Crisp search engine`}</li>
      <li parentName="ul">{`Enjoy Crisp innovation's capacity while using Zendesk as a main dashboard`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      